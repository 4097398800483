export const getFirebaseErrorMessage = (code: string): string => {
  switch (code) {
    case 'auth/invalid-email':
      return 'メールアドレスの形式が間違っています';
    case 'auth/user-disabled':
      return 'このアカウントは無効となっています';
    case 'auth/user-not-found':
      return '該当するメールアドレスが見つかりませんでした';
    case 'auth/wrong-password':
      return 'パスワードが間違っています';
    case 'auth/auth-domain-config-required':
      return 'authDomain構成が提供されていません';
    case 'auth/operation-not-supported-in-this-environment':
      return '「location.protocol」はhttpまたはhttpsである必要があります。';
    case 'auth/unauthorized-domain':
      return 'このドメインは許可されていません';
    case 'auth/account-exists-with-different-credential':
      return '同じメールアドレスで登録されているアカウントがすでに存在します。';
    case 'auth/credential-already-in-use':
      return '既にデータベースにリンクされている認証情報がセットされています。';
    case 'auth/email-already-in-use':
      return '認証情報に対応するメールアドレスがすでに存在しています。';
    case 'auth/timeout':
      return 'このアプリドメインがFirebaseプロジェクトのOAuth操作に対して承認されていません。';
    case 'auth/internal-error':
      return 'システムエラーです';
    case 'auth/too-many-requests':
      return '試行回数が多すぎます。しばらく時間を置いてから再度試行してください。';
    case 'appCheck/fetch-status-error':
      return 'App Checkの検証に失敗しましたが、ログインは続行できます。';
    default:
      return code;
  }
};
