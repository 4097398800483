import React from 'react';
import { motion } from 'framer-motion';
import {
  Alert,
  Box,
  Container,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { labels, publicTheme } from '../../constants';
import { ModalOtherModelCategory } from '../../components/features/user/ModalOtherModelCategory';
import { RoundButton } from '../../components/atoms';
import { ModalAgencyDetail } from '../../components/features/user/ModalAgencyDetail';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useFetchModelCategories,
  useModal,
  usePhotos,
  usePublicUser,
  useReport,
} from '../../hooks';
import { UserRaderChart } from '../../components/features/reports/UserRaderChart';
import { UserAgeSlider } from '../../components/features/user/UserAgeSlider';
import { UserHeightSlider } from '../../components/features/user/UserHeightSlider';
import { getAge } from '../../utils/get-age';
import { AgencyRaderChart } from '../../components/features/reports/AgencyRaderChart';
import { floorDecimal } from '../../utils/floor-decimal';
import { Loading } from '../../components/molecules';
import {
  sumUpCommercialModelScore,
  sumUpFashionModelScore,
} from '../../domains';
import { UserLabelsProperty } from '../../components/features/user/UserLabelsProperty';
import { formatDate } from '../../utils/format-date';

type TipTitleKeys = keyof typeof labels.data.tips.title;

export const UserReportPage = () => {
  const modelCategoryModal = useModal(false);
  const { uid, reportId } = useParams<{ uid: string; reportId: string }>();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const isDebug = mode === 'debug';
  const isSatoruJapan = mode === 'satorujapan';

  const { isFetching, publicUser } = usePublicUser(uid);
  const { report } = useReport(uid, reportId);

  const { modelPhotos } = usePhotos({
    categoryId: report?.similarCategory?.id,
  });

  const { modelCategories } = useFetchModelCategories();

  const isPC = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  if (isFetching) {
    return <Loading />;
  }

  if (!publicUser || !report) {
    return <p>この診断カルテは削除されました。</p>;
  }

  document.title = isSatoruJapan
    ? `サトルジャパン2次審査モデル診断カルテ - ${publicUser.name}`
    : `モデル診断カルテ - ${publicUser.name}`;

  const getTipTitle = (key: TipTitleKeys) => {
    return labels.data.tips.title[key];
  };

  return (
    // テーマの書き換え
    <ThemeProvider theme={publicTheme}>
      {/* デバッグモードで起動中の場合はアラートを表示する */}
      {isDebug && (
        <Alert severity='error'>{labels.message.debugModeIsWorking}</Alert>
      )}

      {/* 赤い背景 */}
      <Box padding={4} bgcolor='primary.main'>
        {/* 横幅調整 */}
        <Container maxWidth={false}>
          {/* 白背景 */}
          <Box sx={{ backgroundColor: 'common.white', borderRadius: '30px' }}>
            {/* ヘッダーエリア */}
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Box pt={4} pr={4} pb={2} pl={4}>
                <Stack
                  direction={isPC ? 'row' : 'column'}
                  justifyContent='space-between'
                >
                  {/* サイト名とサイトロゴ */}
                  <Box
                    sx={{ minWidth: isSatoruJapan ? 300 : 200, flexShrink: 0 }}
                  >
                    <LazyLoadImage
                      src={`${process.env.PUBLIC_URL}/promodelstudio.png`}
                      alt='site logo'
                      width={88}
                      effect='opacity'
                    />
                    {isSatoruJapan ? (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                          }}
                        >
                          サトルジャパン2次審査
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                          }}
                        >
                          モデル診断カルテ
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 'bold',
                        }}
                      >
                        モデル診断カルテ
                      </Typography>
                    )}
                  </Box>

                  {/* 応募者の基本情報エリア */}
                  <Box sx={{ flex: 1, overflow: 'hidden' }}>
                    <Stack
                      spacing={2}
                      direction={isPC ? 'row' : 'column'}
                      sx={{ justifyContent: 'flex-end' }}
                    >
                      <Box
                        sx={{
                          minWidth: '120px',
                          flex: '1 1 auto',
                          maxWidth: '180px',
                        }}
                      >
                        <Typography
                          fontWeight='bold'
                          gutterBottom
                          fontSize={12}
                        >
                          {labels.data.user.name}
                        </Typography>
                        <TextField
                          value={publicUser.name}
                          InputProps={{ readOnly: true }}
                          variant='standard'
                          fullWidth
                        />
                      </Box>
                      {isSatoruJapan ? (
                        <>
                          <Box
                            sx={{
                              minWidth: '120px',
                              flex: '1 1 auto',
                              maxWidth: '160px',
                            }}
                          >
                            <Typography
                              fontWeight='bold'
                              gutterBottom
                              fontSize={12}
                            >
                              {labels.data.user.birthday}
                            </Typography>
                            <TextField
                              value={formatDate(publicUser.birthday)}
                              InputProps={{ readOnly: true }}
                              variant='standard'
                              fullWidth
                            />
                          </Box>
                          <Box
                            sx={{
                              minWidth: '120px',
                              flex: '1 1 auto',
                              maxWidth: '160px',
                            }}
                          >
                            <Typography
                              fontWeight='bold'
                              gutterBottom
                              fontSize={12}
                            >
                              {labels.data.user.height}
                            </Typography>
                            <TextField
                              value={publicUser.height}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    cm
                                  </InputAdornment>
                                ),
                              }}
                              variant='standard'
                              fullWidth
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box
                            sx={{
                              minWidth: '120px',
                              flex: '1 1 auto',
                              maxWidth: '160px',
                            }}
                          >
                            <Typography
                              fontWeight='bold'
                              gutterBottom
                              fontSize={12}
                            >
                              {labels.data.user.height}
                            </Typography>
                            <TextField
                              value={publicUser.height}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    cm
                                  </InputAdornment>
                                ),
                              }}
                              variant='standard'
                              fullWidth
                            />
                          </Box>
                          <Box
                            sx={{
                              minWidth: '120px',
                              flex: '1 1 auto',
                              maxWidth: '160px',
                            }}
                          >
                            <Typography
                              fontWeight='bold'
                              gutterBottom
                              fontSize={12}
                            >
                              {labels.data.user.BWHmeasurements}
                            </Typography>
                            <TextField
                              value={`B: ${publicUser.bust} W: ${publicUser.waist} H: ${publicUser.hip}`}
                              InputProps={{ readOnly: true }}
                              variant='standard'
                              fullWidth
                            />
                          </Box>
                        </>
                      )}
                      <Box
                        sx={{
                          minWidth: '120px',
                          flex: '1 1 auto',
                          maxWidth: '160px',
                        }}
                      >
                        <Typography
                          fontWeight='bold'
                          gutterBottom
                          fontSize={12}
                        >
                          {labels.data.user.race}
                        </Typography>
                        <TextField
                          value={publicUser.race}
                          InputProps={{ readOnly: true }}
                          variant='standard'
                          fullWidth
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </motion.div>

            {/* 応募者画像やチャート、レコメンドなどのメインコンテンツ */}
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <Box padding={4}>
                <Grid container columnSpacing={6} columns={24}>
                  {/* 画像エリア */}
                  <Grid item xs={24} md={5}>
                    <ImageList gap={12} cols={isPC ? 1 : 2}>
                      {/* バストアップ画像 */}
                      {publicUser.bustupImgUrl && (
                        <ImageListItem>
                          <LazyLoadImage
                            src={publicUser.bustupImgUrl}
                            alt={labels.data.userImage.bust}
                            width='100%'
                            effect='opacity'
                          />
                        </ImageListItem>
                      )}

                      {/* 全身画像 */}
                      {publicUser.fullbodyImgUrl && (
                        <ImageListItem>
                          <LazyLoadImage
                            src={publicUser.fullbodyImgUrl}
                            alt={labels.data.userImage.fullbody}
                            width='100%'
                            effect='opacity'
                          />
                        </ImageListItem>
                      )}

                      {/* 真横画像 */}
                      {publicUser.sideImgUrl && (
                        <ImageListItem>
                          <LazyLoadImage
                            src={publicUser.sideImgUrl}
                            alt={labels.data.userImage.side}
                            width='100%'
                            effect='opacity'
                          />
                        </ImageListItem>
                      )}

                      {/* 笑顔画像 */}
                      {publicUser.smileImgUrl && (
                        <ImageListItem>
                          <LazyLoadImage
                            src={publicUser.smileImgUrl}
                            alt={labels.data.userImage.smile}
                            width='100%'
                            effect='opacity'
                          />
                        </ImageListItem>
                      )}
                    </ImageList>
                  </Grid>

                  {/* 本文エリア */}
                  <Grid item xs={24} md={14}>
                    {/* chart */}
                    <Grid container spacing={2} mb={8}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          fontWeight='bold'
                          textAlign='center'
                          fontSize={16}
                        >
                          コマーシャルモデル
                          <Typography
                            fontSize={13}
                            component='span'
                            fontWeight='bold'
                          >
                            としての素質
                          </Typography>
                        </Typography>
                        <UserRaderChart
                          labels={labels.charts.labels.CM}
                          data={
                            report.score?.commercialModelScore
                              ? [
                                  report.score.commercialModelScore.features,
                                  report.score.commercialModelScore.balance / 2,
                                  floorDecimal(
                                    report.score.commercialModelScore.legShape /
                                      3,
                                    1,
                                  ),
                                  floorDecimal(
                                    report.score.commercialModelScore.smile / 2,
                                    1,
                                  ),
                                  report.score.commercialModelScore.height,
                                  report.score.commercialModelScore.nose / 2,
                                  report.score.commercialModelScore.skinQuality,
                                  report.score.commercialModelScore.age,
                                ]
                              : []
                          }
                          labelDisplay={isDebug}
                        />

                        {/* デバッグモードであればチャートの合計点を表示する */}
                        {isDebug && (
                          <Typography align='center'>
                            合計：
                            {Math.floor(
                              sumUpCommercialModelScore(report.score),
                            )}
                            ポイント / 100ポイント
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          fontWeight='bold'
                          textAlign='center'
                          fontSize={16}
                        >
                          ファッションモデル
                          <Typography
                            fontSize={13}
                            component='span'
                            fontWeight='bold'
                          >
                            としての素質
                          </Typography>
                        </Typography>
                        <UserRaderChart
                          labels={labels.charts.labels.FM}
                          data={
                            report.score?.fashionModelScore
                              ? [
                                  report.score.fashionModelScore.height,
                                  report.score.fashionModelScore.balance / 2,
                                  floorDecimal(
                                    report.score.fashionModelScore.legShape / 3,
                                    1,
                                  ),
                                  report.score.fashionModelScore.limbLength / 2,
                                  report.score.fashionModelScore.features,
                                  report.score.fashionModelScore.nose / 2,
                                  report.score.fashionModelScore.skinQuality,
                                  report.score.fashionModelScore.age,
                                ]
                              : []
                          }
                          color='secondary'
                          labelDisplay={isDebug}
                        />

                        {/* デバッグモードであればチャートの合計点を表示する */}
                        {isDebug && (
                          <Typography align='center'>
                            合計：
                            {Math.floor(sumUpFashionModelScore(report.score))}
                            ポイント / 100ポイント
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    {/* あなたにおすすめのモデル事務所 */}
                    <Grid container mb={5}>
                      <Stack spacing={2} direction='row' alignItems='center'>
                        <Typography
                          fontWeight='bold'
                          color='primary.main'
                          fontSize={13}
                          mb={4}
                        >
                          ▶︎ あなたにおすすめのモデル事務所
                        </Typography>
                      </Stack>

                      <Grid container>
                        {report?.recommendAgencies
                          ? report.recommendAgencies
                              .slice(0, 3)
                              .map((agency) => {
                                // agencyのカテゴリーに応じて取得する基準点を変更
                                const criteria = agency.score;

                                const score =
                                  agency.inheritance === 'ファッションモデル'
                                    ? report.score?.fashionModelScore
                                    : report.score?.commercialModelScore;

                                const color =
                                  agency.inheritance === 'ファッションモデル'
                                    ? 'secondary'
                                    : 'primary';

                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={
                                      isSatoruJapan &&
                                      report?.recommendAgencies?.length === 2
                                        ? 6
                                        : 4
                                    }
                                    p={2}
                                    key={agency.id}
                                  >
                                    <Stack
                                      direction='column'
                                      justifyContent='center'
                                      alignItems='center'
                                      rowGap={1}
                                    >
                                      <Typography
                                        fontSize={12}
                                        fontWeight='bold'
                                        gutterBottom
                                      >
                                        {agency.name}
                                      </Typography>
                                      <Typography
                                        textAlign='center'
                                        mb={1}
                                        fontSize={10}
                                      >
                                        [ {agency.area} ]
                                      </Typography>
                                      <AgencyRaderChart
                                        data={[
                                          criteria.height,
                                          criteria.balance / 2,
                                          floorDecimal(
                                            criteria.legShape / 3,
                                            1,
                                          ),
                                          criteria.features,
                                          criteria.skinQuality,
                                          criteria.age,
                                        ]}
                                        data2={[
                                          score?.height,
                                          score?.balance
                                            ? score.balance / 2
                                            : undefined,
                                          score?.legShape
                                            ? floorDecimal(
                                                score.legShape / 3,
                                                1,
                                              )
                                            : undefined,
                                          score?.features,
                                          score?.skinQuality,
                                          score?.age,
                                        ]}
                                        color={color}
                                      />

                                      {/* 受け入れ年齢 */}
                                      <Stack
                                        direction='row'
                                        spacing={2}
                                        mt={4}
                                        mb={2}
                                      >
                                        <Typography
                                          fontSize={10}
                                          fontWeight='bold'
                                        >
                                          ★ 受け入れ年齢
                                        </Typography>
                                        <UserAgeSlider
                                          value={getAge(publicUser.birthday)}
                                          min={agency.minAge}
                                          max={agency.maxAge}
                                        />
                                      </Stack>

                                      {/* 事務所の受け入れ身長 */}
                                      <Stack direction='row' spacing={2} mb={2}>
                                        <Typography
                                          fontSize={10}
                                          fontWeight='bold'
                                        >
                                          ★ 受け入れ身長
                                        </Typography>
                                        <UserHeightSlider
                                          value={publicUser.height}
                                          min={agency.minHeight}
                                          max={agency.maxHeight}
                                        />
                                      </Stack>

                                      {/* 関連するモデル画像モーダルのボタンと開閉 */}
                                      <ModalAgencyDetail agency={agency} />
                                    </Stack>
                                  </Grid>
                                );
                              })
                          : null}
                      </Grid>
                    </Grid>

                    {/* 他候補のモデル事務所 - satorujapanモードでは非表示 */}
                    {!isSatoruJapan && (
                      <Grid container mb={5}>
                        {/* 見出し */}
                        <Grid item xs={24} mb={3}>
                          <Typography
                            fontWeight='bold'
                            fontSize={13}
                            color='primary.main'
                          >
                            ▶︎ 他候補のモデル事務所
                          </Typography>
                        </Grid>

                        {/* おすすめ事務所5つ */}
                        <Grid item xs={24}>
                          <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='space-between'
                          >
                            {report?.recommendAgencies
                              ? report.recommendAgencies
                                  .slice(3, 8)
                                  .map((agency, index) => (
                                    <Stack
                                      key={index}
                                      direction='column'
                                      justifyContent='center'
                                      alignItems='center'
                                    >
                                      <Typography
                                        fontWeight='bold'
                                        textAlign='center'
                                        mb={1}
                                        fontSize={10}
                                      >
                                        {agency.name}
                                      </Typography>

                                      {/* 関連するモデル画像モーダルのボタンと開閉 */}
                                      <ModalAgencyDetail agency={agency} />
                                    </Stack>
                                  ))
                              : null}
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    {/* おすすめの芸能事務所 - satorujapanモードでは非表示 */}
                    {!isSatoruJapan && (
                      <Grid container mb={5}>
                        {/* 見出し */}
                        <Grid item xs={24} mb={3}>
                          <Typography
                            fontWeight='bold'
                            fontSize={13}
                            color='primary.main'
                          >
                            ▶︎ おすすめの芸能事務所
                          </Typography>
                        </Grid>

                        <Grid item xs={24}>
                          <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='space-between'
                          >
                            {report?.recommendTalentAgencies
                              ? report.recommendTalentAgencies
                                  .slice(0, 5)
                                  .map((agency, index) => (
                                    <Stack
                                      key={index}
                                      direction='column'
                                      justifyContent='center'
                                      alignItems='center'
                                    >
                                      <Typography
                                        fontWeight='bold'
                                        textAlign='center'
                                        mb={1}
                                        fontSize={10}
                                      >
                                        {agency.name}
                                      </Typography>

                                      {/* モーダル開閉ボタン */}
                                      <RoundButton
                                        variant='contained'
                                        href={agency.url}
                                        target='_blank'
                                      >
                                        詳しく見る
                                      </RoundButton>
                                    </Stack>
                                  ))
                              : null}
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    {/* 境界線 */}
                    <Divider
                      sx={{ backgroundColor: 'primary.main', marginBottom: 5 }}
                    />

                    {/* 改善ポイントとアドバイス */}
                    <Stack spacing={3}>
                      <Typography fontWeight='bold' fontSize={12}>
                        ▶︎ 改善ポイントとアドバイス
                      </Typography>

                      <Stack spacing={4}>
                        {report.tips?.length
                          ? report.tips.map((tip, index) => (
                              <Stack spacing={2} key={index}>
                                <Typography fontSize={13}>
                                  {report.improvements
                                    ? getTipTitle(
                                        report.improvements[
                                          index
                                        ] as TipTitleKeys,
                                      )
                                    : null}
                                </Typography>
                                <Typography fontSize={11}>
                                  {tip.content}
                                </Typography>
                                <Typography fontWeight='bold' fontSize={10}>
                                  参考記事：
                                  <Link
                                    href={tip.url}
                                    target='_blank'
                                    color='common.black'
                                  >
                                    {tip.url}
                                  </Link>
                                </Typography>
                              </Stack>
                            ))
                          : null}
                      </Stack>
                    </Stack>
                  </Grid>

                  {/* あなたにおすすめのモデルカテゴリー */}
                  <Grid item xs={24} md={5}>
                    <Grid container spacing={4}>
                      {/* おすすめのモデルカテゴリー */}
                      <Grid item xs={12}>
                        <Typography fontSize={12} fontWeight='bold'>
                          ▼ あなたにおすすめのモデルカテゴリー
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={2}>
                        <Typography
                          color='primary.main'
                          fontWeight='bold'
                          fontSize='16'
                        >
                          {report.similarCategory?.name}
                        </Typography>
                        <Box>
                          <RoundButton
                            variant='outlined'
                            onClick={modelCategoryModal.setOpen}
                            size='small'
                          >
                            詳しく見る
                          </RoundButton>
                        </Box>
                      </Grid>

                      {/* あなたの傾向 */}
                      <Grid item xs={12}>
                        <Typography fontSize={12} fontWeight='bold' mb={3}>
                          ▶︎ あなたの傾向
                        </Typography>
                        <Typography fontSize={12} lineHeight={19 / 12}>
                          {report.similarCategory?.description}
                        </Typography>
                      </Grid>

                      {/* 上記3事務所の代表的なカテゴリごとの仕事の画像 */}
                      <Grid item xs={12}>
                        <ImageList cols={2} gap={6}>
                          {modelPhotos.map((item) => (
                            <ImageListItem key={item.id}>
                              <LazyLoadImage
                                src={item.url}
                                alt={item.id}
                                width='100%'
                                effect='opacity'
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </motion.div>
          </Box>

          {/* デバッグモードで起動中の場合はアラートを表示する */}
          {isDebug && (
            <Box mt={3}>
              <UserLabelsProperty publicUser={publicUser} />
            </Box>
          )}
        </Container>
      </Box>

      {/* 他のモデルカテゴリーを表示するモーダル */}
      <ModalOtherModelCategory
        modelCategories={modelCategories}
        isOpen={modelCategoryModal.isOpen}
        setClose={modelCategoryModal.setClose}
        isDebug={isDebug}
      />
    </ThemeProvider>
  );
};
