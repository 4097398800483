import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';
import { Advise } from '.';
import { Agency } from './Agency';
import { ModelCategory } from './ModelCategory';

export type Score = {
  commercialModelScore: {
    features: number;
    balance: number;
    legShape: number;
    smile: number;
    height: number;
    nose: number;
    skinQuality: number;
    age: number;
  };
  fashionModelScore: {
    height: number;
    balance: number;
    legShape: number;
    limbLength: number;
    features: number;
    nose: number;
    skinQuality: number;
    age: number;
  };
};

export type Report = {
  reportId: string;
  version: string;
  isProcessing: boolean;
  createdAt: Date;
  updatedAt: Date;
  score?: Score;
  recommendAgencies?: Agency[];
  recommendTalentAgencies?: Agency[];
  improvements?: string[];
  tips?: Advise[];
  similarCategory?: ModelCategory;
  isSatorujapanMode?: boolean;
};

export type ReportFormData = {
  version: string;
  isProcessing: boolean;
  isSatorujapanMode?: boolean;
};

/**
 * Firestore のドキュメントと Report オブジェクトの型変換を行います。
 */
export const userReportConverter: FirestoreDataConverter<Report> = {
  /**
   * Report オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(report: Report): DocumentData {
    return {
      reportId: report.reportId,
      version: report.version,
      isProcessing: report.isProcessing,
      ...(report.score && { score: report.score }),
      ...(report.recommendAgencies && {
        recommendAgencies: report.recommendAgencies,
      }),
      ...(report.recommendTalentAgencies && {
        recommendTalentAgencies: report.recommendTalentAgencies,
      }),
      ...(report.improvements && { improvements: report.improvements }),
      ...(report.tips && { tips: report.tips }),
      ...(report.similarCategory && {
        similarCategory: report.similarCategory,
      }),
      ...(report.isSatorujapanMode !== undefined && {
        isSatorujapanMode: report.isSatorujapanMode,
      }),
      createdAt: report.createdAt,
      updatedAt: report.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを Report オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Report {
    const data = snapshot.data(options);

    return {
      reportId: data.reportId,
      version: data.version,
      isProcessing: data.isProcessing,
      score: data.score,
      recommendAgencies: data.recommendAgencies,
      recommendTalentAgencies: data.recommendTalentAgencies,
      improvements: data.improvements,
      tips: data.tips,
      similarCategory: data.similarCategory,
      isSatorujapanMode: data.isSatorujapanMode,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
