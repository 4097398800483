import React, { FunctionComponent } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { labels } from '../../../constants';
import { ReportFormData, PublicUser } from '../../../entities';
import { InputList } from '../../atoms';
import { useReportEditTools } from '../../../hooks';

type UserReportCreateFormProps = {
  isOpen: boolean;
  publicUser: PublicUser;
  onClose: () => void;
};

export const UserReportCreateForm: FunctionComponent<
  UserReportCreateFormProps
> = ({ isOpen, publicUser, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ReportFormData>({
    defaultValues: {
      version: '',
      isProcessing: true,
      isSatorujapanMode: false,
    },
  });

  const { createReport } = useReportEditTools();

  const onSubmit: SubmitHandler<ReportFormData> = async (formData) => {
    await createReport(publicUser.uid, formData).then(() => {
      onClose();
      reset();
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{labels.pages.userReport.add}</DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: 0 }}>
          <InputList.Wrapper>
            <InputList.Item label={labels.data.userReport.version}>
              <Controller
                name='version'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size='small'
                    placeholder='1.0.0'
                    error={Boolean(errors?.version)}
                    helperText={errors?.version?.message}
                  />
                )}
              />
            </InputList.Item>
            <InputList.Item label={labels.data.userReport.option}>
              <Controller
                name='isSatorujapanMode'
                control={control}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          size='small'
                          color='primary'
                        />
                      }
                      label={labels.data.userReport.isSatorujapanMode}
                    />
                    <FormHelperText>
                      サトルジャパン2次審査用のレポートとして作成する場合はチェックしてください。
                    </FormHelperText>
                  </>
                )}
              />
            </InputList.Item>
          </InputList.Wrapper>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button sx={{ m: 1 }} variant='outlined' onClick={onClose}>
            {labels.form.actions.cancel}
          </Button>

          {isSubmitting ? (
            <Button sx={{ m: 1 }} variant='contained' disabled>
              {labels.form.actions.loading}
            </Button>
          ) : (
            <Button sx={{ m: 1 }} type='submit' variant='contained'>
              {labels.form.actions.save}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
